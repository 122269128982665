import Reveal from 'reveal.js';
import { gsap } from "gsap";

var _isMobile = false;
var isStatic = false;
var mobileBreakpoint = 992;
var isFocusedRight = false;
var wasTabsContent = false;

$(document).ready(function () {
  console.log("DOC READY");
  init();
});
function init() {
  if (typeof isReveal != "undefined") {
    initReveal();
    handleTimer();
  }

  if (typeof isStaticSlides != "undefined") {
    initStaticSlides();
    handleTimer();
    isStatic = true;
  }  
}

function initReveal() {

  revealInitOptions.margin = 0.0,
    revealInitOptions.minScale = 0.75,
    revealInitOptions.maxScale = 3.0,
    revealInitOptions.mobileViewDistance = 3,
    revealInitOptions.hash = false,
    revealInitOptions.autoSlideMethod = customSlideTransition,
    revealInitOptions.autoSlideStoppable = false,
    revealInitOptions.verticator = {
      darktheme: false,
      color: 'black',
      oppositecolor: 'white',
      skipuncounted: false,
      clickable: true
    };

  Reveal.initialize(revealInitOptions).then(revealReady);
  //Reveal.on( 'hasvideo', handleVideoSlide ); 
  if (isMobile()) {
    _isMobile = true;
    //Reveal.on( 'hasvideo', showAudBtn );
  }
}

/************
* 
  REVEAL JS
*
************/
var allSlides = [];
var slidePointer = 0;
function revealReady() {

  console.log("Reveal ready");

  allSlides = $(".reveal section").get();

  initSwipper(".slides section.present .swiper-container", !isStatic);

  handleDynamicAreas(Reveal.getCurrentSlide());
  handleDynamicHeadlines(Reveal.getCurrentSlide());
}

function initStaticSlides() {
  initCycler(".opening-hours.translate");
  initCycler(".opening-hours-entries.translate");
  initCycler(".prices.translate");
  initCycler(".prices-entries.translate");
  initCycler(".pricecomment.translate");
}

var animationStop;
var animationDelayDefault = 3000;
var animationDelay = animationDelayDefault;
var nextHeadline;
function customSlideTransition(e) {
  clearTimeout(animationStop);

  Reveal.toggleAutoSlide();

  $(".slides section.present").addClass("fadeOutSection");
  $(".slides section").addClass("blurred");

  var nextSlide = allSlides[slidePointer + 1];

  if (!nextSlide)
    nextSlide = allSlides[0];

  handleDynamicAreas(Reveal.getCurrentSlide(), nextSlide, true);
  handleDynamicHeadlines(Reveal.getCurrentSlide(), nextSlide, true);

  animationStop = setTimeout(handleReanimate, animationDelay);
}

function handleReanimate() {

  killSwipers();

  Reveal.next();
  Reveal.toggleAutoSlide();
  slidePointer++;

  if (slidePointer >= allSlides.length)
    slidePointer = 0;

  handleDynamicAreas(Reveal.getPreviousSlide(), Reveal.getCurrentSlide(), false);
  handleDynamicHeadlines(Reveal.getPreviousSlide(), Reveal.getCurrentSlide(), false);

  $(".slides section").removeClass("fadeOutSection");
  $(".slides section").removeClass("blurred");

  initSwipper(".slides section.present .swiper-container", true);
}

function handleDynamicHeadlines(before, after, animateOut) {
  var targetHeadline = $(before).attr("data-state");
  nextHeadline = $(after).attr("data-state");

  if (!after) {
    if ($("body").hasClass("bg-activated")) {
      $(".h1-section .header-container:first-of-type").removeClass("no-display blurred fadeOutSection");
      $("#headline-line").removeClass("blurred fadeOutSection");
    }
    return;
  }

  if (animateOut) {
    if (nextHeadline != targetHeadline) {
      $(".header-container#" + targetHeadline).addClass("blurred fadeOutSection");
      $("#headline-line").addClass("blurred fadeOutSection");
    }
  }
  else {
    if (nextHeadline != targetHeadline) {

      $(".header-container#" + targetHeadline).addClass("no-display blurred fadeOutSection");

      if ($("body").hasClass("bg-activated")) {
        $(".header-container#" + nextHeadline).removeClass("blurred fadeOutSection no-display");
        $("#headline-line").removeClass("blurred fadeOutSection");
      }

    }
  }
}

var footerFor = ["type-kv1", "type-ausstellungen", "type-kv2", "type-sondertag"];
var infoFooterFor = ["type-information"];
var blankStateFor = ["type-blank"];
function handleDynamicAreas(curr, next, animateOut) {

  var currPresentation = $(curr).attr("data-prestype");

  if (!next) {

    loopActivate(footerFor, currPresentation, ".footerevents", "fadeOutSection blurred", false);
    loopActivate(infoFooterFor, currPresentation, ".footerinfo", "fadeOutSection blurred", false);
    loopActivate(blankStateFor, currPresentation, "body", "bg-activated", false);
    return;
  }

  var nextPresentation = $(next).attr("data-prestype");

  if (animateOut) {
    if (currPresentation != nextPresentation) {
      loopDeactivate(footerFor, nextPresentation, ".footerevents", "fadeOutSection blurred");
      loopDeactivate(infoFooterFor, nextPresentation, ".footerinfo", "fadeOutSection blurred");
      loopDeactivate(blankStateFor, nextPresentation, "body", "bg-activated");
    }
  }
  else {
    if (currPresentation != nextPresentation) {
      loopActivate(footerFor, nextPresentation, ".footerevents", "fadeOutSection blurred");
      loopActivate(infoFooterFor, nextPresentation, ".footerinfo", "fadeOutSection blurred");
      loopActivate(blankStateFor, nextPresentation, "body", "bg-activated");
    }
  }
}

function loopActivate(array, keyword, selector, classes) {
  if (arrayContains(array, keyword)) {
    $(selector).removeClass(classes);
  }
}

function loopDeactivate(array, keyword, selector, classes) {

  if (!arrayContains(array, keyword)) {
    $(selector).addClass(classes);
  }

}

/************
* 
  REVEAL JS END
*
************/

/************
* 
  SWIPER JS
*
************/

var swiper;
function initSwipper(query, playOnce) {
  swiperOptions.autoplay = { delay: 1000, stopOnLastSlide: playOnce };
  swiperOptions.effect = 'slide';
  swiperOptions.loop = !playOnce;
  swiperOptions.slidesPerView = 1;

  if(!playOnce){
    var numEls = $(query).find(".swiper-slide").length;
    if (numEls <= 1){
      swiperOptions.loop = false;
    }      
  }
  swiper = new Swiper(query, swiperOptions);
}

function killSwipers() {
  if (swiper && !swiper.isBeginning) {
    console.log("DESTROYING");
    swiper.destroy();
    swiper = null;
  }
}

/************
* 
  SWIPER JS END
*
************/

/************
* 
  CUSTOM ANIMATOR
*
************/
var animationTime = 0.8;
var tl;
//var tl = gsap.timeline({repeat: -1});
function initCycler(selector) {

  var containers = $(selector).get();

  containers.forEach(element => {
    tl = gsap.timeline({ repeat: -1 });
    var elements = $(element).find(".lang-el");
    for (var t = 0; t < elements.length; t++) {
      cycleElementsShow(elements[t]);
      cycleElementsHide(elements[t]);
    }
  });
}
function cycleElementsShow(el) {
  tl.to(el, { opacity: 1, display: "inline-block", duration: animationTime });
}
function cycleElementsHide(el) {
  tl.to(el, { opacity: 0, display: "none", duration: animationTime, delay: delayTime });
}

/************
* 
  CUSTOM ANIMATOR END
*
************/



/************
 * 
 *  HELPERS
 * 
 ************/
function bodyHasClasses(_classes) {
  var result = false;
  _classes.forEach(element => {
    result = result || document.body.classList.contains(element);
  });
  return result;
}

function arrayContains(arr, keyword) {
  return (arr.indexOf(keyword) > -1);
}

function toggleCookiesPanel() {
  $(".cc-revoke").fadeToggle();
  $(".cc-window").fadeToggle();
  $(".cc-window").toggleClass("cc-invisible");
}

function isMobile() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  }
  else {
    return false
  }
}

/************
 *
 *  HELPERS END
 *
 ************/

/************
 *
 *  AUTOMATION
 *
 ************/

var refreshTimer;
var timerTimeNormal = 1000 * 60 * 60 * refreshTimerNormal;
var timerTimeShort = 1000 * 60 * 60 * refreshTimerShort;
var timerTime = timerTimeNormal; //to seconds - seconds - minutes
var currURL = "";
function triggerReload() {
  currURL = window.location.href;

  console.log("timer: " + timerTimeNormal);
  console.log("timer short: " + timerTimeShort);

  $.ajax({
    url: currURL,
    beforeSend: function (xhr) {
    }
  })
    .done(function (data) {
      handleRefresh();
    })
    .fail(function () {
      handleRefreshError();
    });
}
function handleTimer() {
  if(refreshTimer)
    clearTimeout(refreshTimer);

  refreshTimer = setTimeout(triggerReload, timerTime);
}

function handleRefresh() {
  location.reload(true);
}

function handleRefreshError() {
  console.log("error reloading");
  timerTime = timerTimeShort;

  clearTimeout(refreshTimer);
  refreshTimer = setTimeout(triggerReload, timerTime);
}

/************
*
*  AUTOMATION END
*
************/




/************
*
*  TEXTFIT JS END
*
************/
/* function checkTextInstances() {

  var containers = document.querySelectorAll('.slides section:not(.stack)');

  for (var t = 0; t < containers.length; t++) {
    var els = containers[0].querySelectorAll('.box p');
    var amount = els.length;
    var maxH = (100 - 10) / amount;

    for (var e = 0; e < els.length; e++) {
      els[e].setAttribute("style", "max-height:" + maxH + "vh;");
    }
  }

  //initTextFit();
}
function initTextFit() {
  var settings = {
    alignVert: true, // if true, textFit will align vertically using css tables
    alignHoriz: false, // if true, textFit will set text-align: center
    multiLine: false, // if true, textFit will not set white-space: no-wrap
    detectMultiLine: true, // disable to turn off automatic multi-line sensing
    minFontSize: 6,
    maxFontSize: 80,
    reProcess: true, // if true, textFit will re-process already-fit nodes. Set to 'false' for better performance
    widthOnly: false, // if true, textFit will fit text to element width, regardless of text height
    alignVertWithFlexbox: true, // if true, textFit will use flexbox for vertical alignment
  };
  textFit(document.querySelectorAll('.box p'), settings);
} */
/************
*
*  TEXTFIT JS END
*
************/

/* function handleVideoSlide(e){
  console.log("has video ");
  Reveal.on( 'slidetransitionend',videoShowing );
} */

/* function videoShowing(){
  Reveal.off( 'slidetransitionend',videoShowing );

  var currVideo = $(".slide-background.present video");
  var currSlide = $(".slides section.present:not(.stack)");
} */

/*function showAudBtn() {
  console.log("showAudBtn");

  $("#audBtn").show();

  var audbtn = $("#audBtn")[0];
  audbtn.addEventListener ('touchstart', unmutePresentation);
  audbtn.addEventListener ('click', unmutePresentation);
}

 function hideAudBtn(){
  console.log("hideAudBtn");

  $("#audBtn").hide();
  var audbtn = $("#audBtn")[0];
  audbtn.removeEventListener ('touchstart', unmutePresentation);
  audbtn.removeEventListener ('click', unmutePresentation);

  Reveal.off( 'hasvideo', showAudBtn );
} */

/* function unmutePresentation(){
  console.log("unmutePresentation");

  hideAudBtn();

  var videos = $("video");
  for(var t = 0; t < videos.length; t++){
    if (videos[t].muted) {
      videos[t].muted = false;
      console.log("was muted");
    }
    else{
      console.log("was unmuted");
    }
  }
} */




